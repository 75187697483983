import moment from "moment";

export const changeValidateCupon = ({
  endDate,
  endHour,
}: {
  endDate: string;
  endHour: string;
}): string | null => {
  const day = endDate.split("-")[2];
  const month = endDate.split("-")[1];
  const year = endDate.split("-")[0];

  if (!+day || !+month || !+year) {
    return null;
  }

  return `Expira em ${day}/${month}/${year} ${
    endHour.length === 1 && !+endHour[0] ? "" : `às ${endHour}`
  }`;
};

export const formatDateWithHours = (date: string, hour: string) => {
  const day = date?.split("-")[2];
  const month = date?.split("-")[1];
  const year = date?.split("-")[0];

  if (!+day || !+month || !+year || !hour) {
    return null;
  }

  return (
    day + "/" + month + "/" + year + "  -  " + hour?.replace(":", "h") ?? ""
  );
};

export const generateDateAndHour =
  (): `${string}/${string}/${string}-${string}:${string}` => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${formatTo2Digits(day)}/${formatTo2Digits(
      month
    )}/${year}-${formatTo2Digits(hour)}:${formatTo2Digits(minute)}`;
  };

const splitDateAndHour = (
  dateAndHour: `${string}/${string}/${string}-${string}:${string}`
) => {
  const arr = dateAndHour.split("-");
  const date = arr[0];
  const hourAndMinute = arr[1];

  const day = date.split("/")[0];
  const month = date.split("/")[1];
  const year = date.split("/")[2];

  const hour = hourAndMinute.split(":")[0];
  const minute = hourAndMinute.split(":")[1];

  return { day, month, year, hour, minute };
};

export const expireOneDay = (
  dateAndHour: `${string}/${string}/${string}-${string}:${string}`
) => {
  const { day, month, year, hour, minute } = splitDateAndHour(dateAndHour);

  const {
    day: currentDay,
    month: currentMonth,
    year: currentYear,
    hour: currentHour,
    minute: currentMinute,
  } = splitDateAndHour(generateDateAndHour());

  if (+currentYear > +year) {
    return true;
  }

  if (+currentMonth > +month) {
    return true;
  }

  if (+currentDay - +day > 1) {
    return true;
  }

  if (+currentDay - +day === 1) {
    if (+currentHour > +hour) {
      return true;
    } else if (+currentHour === +hour) {
      if (+currentMinute > +minute) {
        return true;
      } else if (+currentMinute === +minute) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  return false;
};

export const formatTo2Digits = (num: number): string => {
  if (num < 10) {
    return "0" + num;
  }

  return `${num}`;
};
export const validateBirthDate = (date: string) => {
  const copyDate = moment(date);
  const min = moment("1889-12-31");

  if (copyDate > min) {
    return date;
  }
  return null;
};
